import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ECoinDropGameProgess } from "../enums";

const initialState: TCoinDropInitialState = {
   gameProgress: ECoinDropGameProgess.LANDING,
   collectedCoins: 0,
};

export const coinDropSlicer = createSlice({
   name: "Coin Drop",
   initialState,
   reducers: {
      setCoinDropGameProgress: (
         state,
         action: PayloadAction<ECoinDropGameProgess>
      ) => {
         state.gameProgress = action.payload;
      },

      setCollectedCoins: (state, action: PayloadAction<number>) => {
         state.collectedCoins = action.payload;
      },
   },
});

export const { setCoinDropGameProgress, setCollectedCoins } =
   coinDropSlicer.actions;
export default coinDropSlicer.reducer;
