'use client';

import { FC } from 'react';
import styles from './ExplanationItem.module.scss';

type TProps = {
    title: string;
    reward: FC;
    item: FC;
};

export const ExplanationItem = ({ title, reward: Reward, item: Item }: TProps) => {
    return (
        <div className={styles.explanationItem}>
            <div className={styles.rewardIcon}>
                <Reward />
            </div>

            <div className={styles.item}>
                <Item />
            </div>

            <h1 className={styles.title}>{title}</h1>
        </div>
    );
};
