import anime from "animejs";
import gsap from "gsap";
import { RefObject } from "react";
import {
   TElementAppearProps,
   TElementVisibilityChangeWithMovementProps,
} from "types/element-animation";

function elementDisappearAnimation({
   element,
   duration = 0.3,
   onComplete,
   delay = 0,
   stringRef,
}: TElementAppearProps) {
   const targetElement = element?.current ?? `.${stringRef}`;

   if (!targetElement) return;

   gsap.to(targetElement, {
      opacity: 0,
      duration,
      delay,
      ease: "sine.inOut",
      onComplete,
   });
}

function elementDisappearWithMovementAnimation({
   delay = 0,
   duration = 0.3,
   element,
   onComplete,
   direction = "up",
}: TElementVisibilityChangeWithMovementProps) {
   if (!element?.current) return;

   gsap.to(element?.current, {
      opacity: 0,
      marginTop: direction === "up" ? "-10rem" : "10rem",
      duration,
      delay,
      onComplete,
      ease: "sine.inOut",
   });
}

function elementAppearAnimation({
   element,
   onComplete,
   stringRef,
}: TElementAppearProps) {
   const targetElement = element?.current ?? `.${stringRef}`;

   if (!targetElement) return;

   gsap.to(targetElement, {
      opacity: 1,
      duration: 0.3,
      ease: "sine.inOut",
      onComplete,
   });
}

function elementAppearWithMovementAnimation({
   delay = 0,
   duration = 0.3,
   element,
   onComplete,
   direction = "up",
}: TElementVisibilityChangeWithMovementProps) {
   if (!element?.current) return;

   direction === "up"
      ? (element.current.style.marginTop = "-10rem")
      : (element.current.style.marginTop = "10rem");

   gsap.to(element?.current, {
      opacity: 1,
      marginTop: direction === "up" ? "0" : "-0",
      duration,
      delay,
      onComplete,
      ease: "sine.inOut",
   });
}

const show = (
   targets: RefObject<HTMLElement> | string,
   duration: number = 900
) => {
   anime({
      targets,
      opacity: [0, 1],
      duration,
      easing: "easeInOutSine",
   });
};

const hide = (
   targets: RefObject<HTMLElement> | string,
   duration: number = 900
) => {
   anime({
      targets,
      opacity: [1, 0],
      duration,
      easing: "easeInOutSine",
   });
};

const up = (
   targets: RefObject<HTMLElement> | string,
   stops: number[],
   duration: number = 900
) => {
   anime({
      targets,
      top: stops,
      duration,
      easing: "easeInOutSine",
   });
};

export {
   elementAppearAnimation,
   elementAppearWithMovementAnimation,
   elementDisappearAnimation,
   elementDisappearWithMovementAnimation,
   show,
   hide,
   up,
};
