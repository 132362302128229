import gsap from "gsap";
import { Assets, Rectangle, Sprite, Ticker } from "pixi.js";
import * as PIXI from "pixi.js";
import { Dispatch, SetStateAction } from "react";

import { detectCollision } from "../../utils";

type TProps = {
   setPointsScored: Dispatch<SetStateAction<number>>;
};

/* TODO make game be faster in last 10 seconds  */

export const coinDropGame = async ({ setPointsScored }: TProps) => {
   const app = new PIXI.Application();

   await app.init({
      antialias: true,
      height: window.innerHeight,
      width: window.innerWidth,
      backgroundAlpha: 0,
      resizeTo: window,
   });

   const background = Sprite.from(Assets.get("background"));

   let pot: Sprite;
   pot = Sprite.from(Assets.get("pot"));
   pot.scale.set(0.8);

   if (window.innerWidth < 1024) {
      pot = Sprite.from(Assets.get("small-pot"));
      pot.scale.set(1.2);
   }

   pot.anchor.set(0.5);
   pot.position.set(window.innerWidth / 2, window.innerHeight / 1.2);

   pot.interactive = true;

   // For the pot
   const updatePotHitArea = () => {
      const potHitX = (-pot.width / 2) * 0.75; // Centered horizontally
      const potHitY = -pot.height * 0.5 + pot.height * 0.3;
      const potHitWidth = pot.width;
      const potHitHeight = pot.height * 0.1;

      console.log({
         potHitX,
         potHitY,
         potHitWidth,
         potHitHeight,
         width: pot.width,
      });

      pot.hitArea = new Rectangle(potHitX, potHitY, potHitWidth, potHitHeight);
   };

   updatePotHitArea();

   if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      pot.scale.set(0.9);
   } else if (window.innerWidth < 768 && window.innerWidth >= 540) {
      pot.scale.set(0.8);
   } else if (window.innerWidth < 540 && window.innerWidth >= 325) {
      pot.scale.set(0.7);
   } else if (window.innerWidth < 325) {
      pot.scale.set(0.4);
   }

   updatePotHitArea();

   app.stage.addChild(background, pot);

   let potDraggable: boolean;

   app.stage.on("mousemove", (event: MouseEvent) => {
      if (potDraggable) {
         pot.x = event.clientX;
      }
   });

   document.querySelector("body")?.addEventListener("mousemove", (event) => {
      if (potDraggable) {
         document.querySelector("body")!.style.cursor = "grabbing";
         pot.x = event.clientX;
      }
   });

   document.querySelector("body")?.addEventListener("mouseover", () => {
      document.querySelector("body")!.style.cursor = "grab";
   });
   document.querySelector("body")?.addEventListener("mousedown", () => {
      potDraggable = true;
      if (potDraggable) {
         document.querySelector("body")!.style.cursor = "grabbing";
      }
   });
   document.querySelector("body")?.addEventListener("touchstart", () => {
      potDraggable = true;
   });

   document.querySelector("body")?.addEventListener("mousemove", (event) => {
      if (potDraggable) {
         document.querySelector("body")!.style.cursor = "grabbing";
         pot.x = event.clientX;
      }
   });
   document
      .querySelector("body")
      ?.addEventListener("touchmove", (event: TouchEvent) => {
         if (potDraggable) {
            pot.x = event.touches[0].clientX;
         }
      });
   document.querySelector("body")?.addEventListener("mouseup", () => {
      potDraggable = false;
      if (!potDraggable) {
         document.querySelector("body")!.style.cursor = "grab";
      }
   });
   document.querySelector("body")?.addEventListener("touchend", () => {
      potDraggable = false;
   });

   /*TODO: CHANGE QUANTITY AND SPEED ON MOBILE*/

   const createCoin = () => {
      if (app && app.stage) {
         const availableAssets = ["coin", "clover", "boot", "hat"];
         const randomValue = Math.random();
         let selected: number;

         if (randomValue < 0.6) {
            selected = 0;
         } else if (randomValue < 0.8) {
            selected = 1;
         } else if (randomValue < 0.9) {
            selected = 2;
         } else {
            selected = 3;
         }

         const coin = Sprite.from(Assets.get(availableAssets[selected]));
         coin.anchor.set(0.5);

         // For the coin
         let coinHitX = -coin.width * 0.5 * 0.25;
         let coinHitY = coin.height * 0.5;
         let coinHitWidth = coin.width * 0.5;
         let coinHitHeight = coin.height * 0.1;
         coin.hitArea = new Rectangle(
            coinHitX,
            coinHitY,
            coinHitWidth,
            coinHitHeight
         );

         let scaleMultiplier = 0.4;

         if (window.innerWidth < 1024) {
            scaleMultiplier = 0.2;
         } else if (window.innerWidth < 400) {
            scaleMultiplier = 0.05;
         }

         coin.scale.set(scaleMultiplier + Math.random() * 0.4);

         coinHitX = -coin.width * 0.5 * 0.25;
         coinHitY = coin.height * 0.5;
         coinHitWidth = coin.width * 0.5;
         coinHitHeight = coin.height * 0.1;
         coin.hitArea = new Rectangle(
            coinHitX,
            coinHitY,
            coinHitWidth,
            coinHitHeight
         );

         // Ensure width between 30 and 50 pixels if screen width is smaller than 400px
         if (window.innerWidth <= 400) {
            coin.width = 30 + Math.random() * 20;
            coin.scale.y = coin.scale.x; // Maintain aspect ratio
         }

         const padding = 50;
         coin.x = padding + Math.random() * (window.innerWidth - padding * 2);
         coin.y = -coin.height;

         app.stage.addChild(coin);

         let speed: number;
         speed = 6 + Math.random() * 9; // Increase speed

         if (window.innerWidth <= 1024) {
            speed = 5 + Math.random() * 7;
         }

         if (window.innerWidth <= 768) {
            speed = 3 + Math.random() * 4;
         }

         if (window.innerHeight < 400) {
            speed = 3;
         }

         console.log({ speed });

         const fall = () => {
            coin.y += speed;
            coin.rotation += 0.01;

            if (detectCollision(coin, pot)) {
               if (
                  availableAssets[selected] === "boot" ||
                  availableAssets[selected] === "hat"
               ) {
                  const redScreen = Sprite.from(Assets.get("red-screen"));

                  redScreen.width = window.innerWidth;
                  redScreen.height = window.innerHeight;
                  redScreen.alpha = 0;

                  if (app && app.stage) {
                     app.stage.addChild(redScreen);

                     if (redScreen) {
                        gsap.to(redScreen, {
                           alpha: 1,
                           duration: 0.2,
                           ease: "sine.inOut",
                           onComplete() {
                              gsap.to(redScreen, {
                                 alpha: 0,
                                 duration: 0.2,
                                 ease: "sine.inOut",
                                 onComplete() {
                                    if (app && app.stage && redScreen) {
                                       app.stage.removeChild(redScreen);
                                    }
                                 },
                              });
                           },
                        });
                     }

                     setPointsScored((score: number) => {
                        const newScore = Math.max(0, score - 25);
                        return newScore;
                     });
                  }
               } else {
                  if (availableAssets[selected] === "clover") {
                     setPointsScored((score: number) => score + 100);
                  } else if (availableAssets[selected] === "coin") {
                     setPointsScored((score: number) => score + 10);
                  }
               }

               if (app && app.stage) {
                  gsap.to(coin, {
                     scale: 0,
                     duration: 0.2,
                     ease: "sine.inOut",
                     onUpdate() {
                        coin.scale.set(
                           scaleMultiplier - scaleMultiplier * this.progress()
                        );
                     },
                     onComplete() {
                        app.stage.removeChild(coin);
                     },
                  });

                  Ticker.shared.remove(fall);
               }
            } else if (coin.y > window.innerHeight) {
               if (app && app.stage) {
                  app.stage.removeChild(coin);

                  Ticker.shared.remove(fall);
               }
            }
         };

         Ticker.shared.add(fall);
      }
   };

   const gameLoop = () => {
      const spawnProbability = window.innerWidth < 400 ? 0.03 : 0.06; // Decrease spawn probability
      if (Math.random() < spawnProbability) {
         createCoin();
      }
   };

   Ticker.shared.add(gameLoop);

   const targetElement = document.querySelector("#coin-drop-game-container");

   if (!targetElement) return;

   targetElement.appendChild(app.canvas);
};
