'use client';

import { FC } from 'react';
import styles from './MobileTutorialItem.module.scss';

type TProps = {
    reward: FC;
    item: FC;
    title: string;
};

export const MobileTutorialItem = ({ item: Item, reward: Reward, title }: TProps) => {
    return (
        <div className={styles.mobileTutorialItem}>
            <div className={styles.item}>
                <div className={styles.reward}>
                    <Reward />
                </div>

                <Item />
            </div>

            <div className={styles.divider}></div>

            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></h1>
        </div>
    );
};
