import cn from "classnames";
import { MouseEvent, ReactNode } from "react";

// import { CoinDropTrianglePointer } from "../../../../icons";
import { ReactComponent as CoinDropTrianglePointer } from "../../../../assets/pointer-triangle.svg";

import styles from "./Button.module.scss";

type TProps = {
   onClick: VoidFunction;
   children: ReactNode;
   disabled?: boolean;
   classname?: string;
};

export const CoinDropButton = ({
   children,
   onClick,
   classname = "",
   disabled = false,
}: TProps) => {
   const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();

      onClick();
   };

   return (
      <button
         className={cn(styles.button, classname)}
         onClick={handleClick}
         disabled={disabled}
      >
         {children}{" "}
         <span>
            <CoinDropTrianglePointer />
         </span>
      </button>
   );
};
