import { FC } from "react";
import { ReactComponent as BootIcon } from "../../../../assets/boot.svg";
import { ReactComponent as CloverIcon } from "../../../../assets/clover.svg";
import { ReactComponent as CoinIcon } from "../../../../assets/coin.svg";
import { ReactComponent as CoinReward } from "../../../../assets/coin-reward.svg";
import { ReactComponent as CloverReward } from "../../../../assets/clover-reward.svg";
import { ReactComponent as BootFine } from "../../../../assets/boot-fine.svg";

type TMobileTutorialItem = {
   reward: FC;
   item: FC;
   title: string;
};

export const mobileTutorialItems: TMobileTutorialItem[] = [
   {
      reward: CoinReward as unknown as FC,
      item: CoinIcon as unknown as FC,
      title: "<span>COLLECT</span> falling gold coins to earn points",
   },
   {
      reward: CloverReward as unknown as FC,
      item: CloverIcon as unknown as FC,
      title: "Golden Clovers worth bonus points",
   },
   {
      reward: BootFine as unknown as FC,
      item: BootIcon as unknown as FC,
      title: "<span>AVOID</span> the pesky leprechaun trash!",
   },
];
