"use client";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
   elementAppearAnimation,
   elementDisappearAnimation,
} from "../../../../animations/elementAnimation.animation";
import { ECoinDropGameProgess } from "../../../../enums";
import { CoinDropLogo } from "../../../../icons";
import { setCoinDropGameProgress } from "../../../../slicers";
import { CoinDropButton } from "../../PulsingButton/Button";
import { mobileTutorialItems } from "./const";

import styles from "./MobileTutorial.module.scss";

import { MobileTutorialItem } from "./MobileTutorialItem";

export const MobileTutorial = () => {
   const dispatch = useDispatch();

   useEffect(() => {
      elementAppearAnimation({ stringRef: styles.mobileTutorial });

      return () => {
         elementDisappearAnimation({ stringRef: styles.mobileTutorial });
      };
   }, []);

   const handleNextStage = () => {
      elementDisappearAnimation({
         stringRef: styles.mobileTutorial,
         onComplete() {
            dispatch(setCoinDropGameProgress(ECoinDropGameProgess.GAME));
         },
      });
   };

   return (
      <section className={styles.mobileTutorial}>
         <div className={styles.mobileTutorialSection}>
            <CoinDropLogo className={styles.logo} />

            {mobileTutorialItems.map((item, index) => (
               <MobileTutorialItem
                  key={index}
                  title={item.title}
                  item={item.item}
                  reward={item.reward}
               />
            ))}
         </div>

         <CoinDropButton classname={styles.button} onClick={handleNextStage}>
            Tap To Play
         </CoinDropButton>
      </section>
   );
};
