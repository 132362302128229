import { Rectangle, Sprite } from "pixi.js";

export function detectCollision(sprite1: Sprite, sprite2: Sprite) {
   const hitArea1 = sprite1.hitArea as Rectangle;
   const hitArea2 = sprite2.hitArea as Rectangle;

   const rect1 = new Rectangle(
      sprite1.x + hitArea1.x,
      sprite1.y + hitArea1.y,
      hitArea1.width,
      hitArea1.height
   );

   const rect2 = new Rectangle(
      sprite2.x + hitArea2.x,
      sprite2.y + hitArea2.y,
      hitArea2.width,
      hitArea2.height
   );

   return (
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y
   );
}
