import { MouseEvent, ReactNode, RefObject } from "react";

import "../../styles/fonts.scss";
import "./IconedButton.css";

interface IconedButtonProps {
   icon: ReactNode;
   onClick: VoidFunction;
   disabled?: boolean;
   buttonRef?: RefObject<HTMLButtonElement>;
   classname?: string;
}

export default function IconedButton({
   icon,
   onClick,
   disabled,
   buttonRef,
   classname,
}: IconedButtonProps) {
   const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      onClick();
   };

   return (
      <button
         disabled={disabled}
         className={`iconed-button ${classname}`}
         onClick={handleClick}
         ref={buttonRef}
      >
         {icon}
      </button>
   );
}
