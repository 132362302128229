"use client";

import { CoinDropButton } from "./Button";
import styles from "./PulsingButton.module.scss";

type TProps = {
   onClick: VoidFunction;
   label: string;
};

export const PulsingButton = ({ onClick, label }: TProps) => {
   return (
      <div className={styles.pulsingButton} onClick={onClick}>
         <CoinDropButton onClick={onClick} classname={styles.button}>
            {label}
         </CoinDropButton>
      </div>
   );
};
