"use client";

import gsap from "gsap";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { ECoinDropGameProgess } from "../../../enums";
// import { CoinDropLogo } from "../../../icons";

import { ReactComponent as CoinDropLogo } from "../../../assets/logo.svg";

import { setCoinDropGameProgress } from "../../../slicers";
import { PulsingButton } from "../PulsingButton";

import styles from "./Landing.module.scss";

export const LandingPage = () => {
   const dispatch = useDispatch();

   const handleBegin = () => {
      gsap.to(`.${styles.contentWrapper}`, {
         opacity: 0,
         duration: 0.5,
         ease: "sine.inOut",
         onComplete() {
            gsap.to(`.${styles.landing}`, {
               opacity: 0,
               duration: 0.5,
               ease: "sine.inOut",
               onComplete() {
                  dispatch(
                     setCoinDropGameProgress(ECoinDropGameProgess.TUTORIAL)
                  );
               },
            });
         },
      });
   };

   useEffect(() => {
      gsap.to(`.${styles.landing}`, {
         opacity: 1,
         duration: 0.5,
         ease: "sine.inOut",
      });
   }, []);

   return (
      <section className={styles.landing}>
         <section className={styles.contentWrapper}>
            <CoinDropLogo />

            <div className={styles.buttonWrapper}>
               <div className={styles.pulsingBorder}></div>

               <PulsingButton onClick={handleBegin} label="Tap to begin" />
            </div>
         </section>
      </section>
   );
};
