import "./App.css";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { CoinDropGame, LandingPage, Summary, Tutorial } from "./components";
import { ECoinDropGameProgess } from "./enums";

const GetPageByKey = ({ gameKey }: { gameKey: ECoinDropGameProgess }) => {
   switch (gameKey) {
      case ECoinDropGameProgess.LANDING:
         return <LandingPage />;
      case ECoinDropGameProgess.GAME:
         return <CoinDropGame />;
      case ECoinDropGameProgess.TUTORIAL:
         return <Tutorial />;
      case ECoinDropGameProgess.SUMMARY:
         return <Summary />;
      default:
         return null;
   }
};

function App() {
   const { gameProgress } = useSelector((state: RootState) => state.coinDrop);

   return (
      <main>
         <GetPageByKey gameKey={gameProgress} />
      </main>
   );
}

export default App;
