"use client";

import { useWindowSize } from "@react-hookz/web";
import gsap from "gsap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
   elementAppearAnimation,
   elementDisappearAnimation,
} from "../../../animations/elementAnimation.animation";
import { ECoinDropGameProgess } from "../../../enums";
import { setCoinDropGameProgress } from "../../../slicers";
import { PulsingButton } from "../PulsingButton";
import { ExplanationSection } from "./ExplanationSection";

import styles from "./Tutorial.module.scss";
import { MobileTutorial } from "./MobileTutorial";

export * from "./MobileTutorial";

export const Tutorial = () => {
   const dispatch = useDispatch();

   const { width } = useWindowSize();

   const [loaded, setLoaded] = useState<boolean>(false);

   const handleStartGame = () => {
      gsap.to(`.${styles.tutorial}`, {
         opacity: 0,
         duration: 0.5,
         ease: "sine.inOut",
         onComplete() {
            dispatch(setCoinDropGameProgress(ECoinDropGameProgess.GAME));
         },
      });
   };

   useEffect(() => {
      setLoaded(true);

      elementAppearAnimation({ stringRef: styles.tutorial });

      return () => {
         elementDisappearAnimation({ stringRef: styles.tutorial });
      };
   }, []);

   return (
      <section className={styles.tutorial}>
         {loaded && <>
            {width < 768 ? <MobileTutorial /> : <>
               <ExplanationSection />
               <PulsingButton label="Tap to play" onClick={handleStartGame} />
            </>
            }

         </>}
      </section>
   );
};
