"use client";

import gsap from "gsap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { elementDisappearAnimation } from "../../../animations/elementAnimation.animation";
import IconedButton from "../../../components/IconedButton";
import { ECoinDropGameProgess } from "../../../enums";
import { CoinDropLogo, ExitIcon, RestartIcon, ShareIcon } from "../../../icons";
import { setCoinDropGameProgress, setCollectedCoins } from "../../../slicers";
import { RootState } from "../../../store";
import { formatNumberWithComa } from "../../../utils";
// import { Share } from "../Share";

import styles from "./Summary.module.scss";

export const Summary = () => {
   const dispatch = useDispatch();
   const { collectedCoins } = useSelector((state: RootState) => state.coinDrop);

   const [shareOpened, setShareOpened] = useState<boolean>(false);

   const restartGame = () => {
      gsap.to(`.${styles.summary}`, {
         opacity: 0,
         duration: 0.3,
         ease: "sine.inOut",
         onComplete() {
            dispatch(setCoinDropGameProgress(ECoinDropGameProgess.GAME));
            dispatch(setCollectedCoins(0));
         },
      });
   };

   const exit = () => {
      elementDisappearAnimation({
         stringRef: `${styles.summaryContent}`,
         onComplete() {
            elementDisappearAnimation({
               stringRef: `${styles.summary}`,
               onComplete() {
                  dispatch(
                     setCoinDropGameProgress(ECoinDropGameProgess.LANDING)
                  );
               },
            });
         },
      });
   };

   const share = () => {
      gsap.to(`.${styles.summaryContent}`, {
         opacity: 0,
         duration: 0.3,
         ease: "sine.inOut",
         onComplete() {
            setShareOpened(true);
         },
      });
   };

   useEffect(() => {
      gsap.to(`.${styles.summary}`, {
         opacity: 1,
         duration: 0.3,
         ease: "sine.inOut",
         onComplete() {
            gsap.to(`.${styles.summaryContent}`, {
               opacity: 1,
               duration: 0.3,
               ease: "sine.inOut",
            });
         },
      });
   }, []);

   useEffect(() => {
      if (!shareOpened) {
         gsap.to(`.${styles.summaryContent}`, {
            opacity: 1,
            duration: 0.3,
            ease: "sine.inOut",
         });
      }
   }, [shareOpened]);

   return (
      <>
         <section className={styles.summary}>
            <div className={styles.logo}><CoinDropLogo /></div>

            {/* {shareOpened ? (
               <Share onClose={() => setShareOpened(false)} />
            ) : ( */}
            <section className={styles.summaryContent}>
               <h3 className={styles.title}>
                  <span>Good job!</span> You scored:
               </h3>

               <h1 className={styles.points}>
                  {formatNumberWithComa(collectedCoins)}
               </h1>

               <div className={styles.buttons}>
                  {/* <IconedButton
                     icon={<ShareIcon />}
                     onClick={share}
                     classname={styles.iconedButton}
                  /> */}
                  <IconedButton
                     icon={<RestartIcon />}
                     onClick={restartGame}
                     classname={styles.iconedButton}
                  />
                  <IconedButton
                     icon={<ExitIcon />}
                     onClick={exit}
                     classname={styles.iconedButton}
                  />
               </div>
            </section>
            {/* )} */}
         </section>
      </>
   );
};
