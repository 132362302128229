"use client";

import cn from "classnames";
import gsap from "gsap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ECoinDropGameProgess } from "../../../enums";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { coinDropGame } from "../../../pixi/coin-drop";
import { setCoinDropGameProgress, setCollectedCoins } from "../../../slicers";

import styles from "./CoinDrop.module.scss";

import { initAssets } from "./const";

/*TODO Make pot moving on window move not only pot move
 */

export const CoinDropGame = () => {
   const dispatch = useDispatch();

   const [isLoaded, setIsLoaded] = useState<boolean>(false);
   const [pointsScored, setPointsScored] = useState<number>(0);
   const [seconds, setSeconds] = useState<number>(30);

   useEffect(() => {
      initAssets().then((inited) => {
         setIsLoaded(inited);
      });
   }, []);

   useEffect(() => {
      if (!isLoaded) {
         return;
      }

      coinDropGame({ setPointsScored });

      gsap.to("#coin-drop-game-container", {
         opacity: 1,
         duration: 0.5,
         ease: "sine.inOut",
      });
   }, [isLoaded]);

   useEffect(() => {
      if (seconds === 0) {
         gsap.to("#coin-drop-game-container", {
            opacity: 0,
            duration: 0.5,
            delay: 0.5,
            ease: "sine.inOut",
            onComplete() {
               dispatch(setCollectedCoins(pointsScored));
               dispatch(setCoinDropGameProgress(ECoinDropGameProgess.SUMMARY));
            },
         });
      }
   }, [dispatch, seconds]);

   const handleFinish = () => { };

   return (
      <section
         id="coin-drop-game-container"
         className={cn(styles.gameCanvas, {
            [styles.devMode]: process.env.NODE_ENV === "development",
         })}
      >
         <h1 className={styles.counter}>Score: {pointsScored}</h1>
         <section className={styles.timer}>
            <CountdownCircleTimer
               colors={["#17BA09", "#F5BD20", "#FC1F1F"]}
               colorsTime={[30, 15, 0]}
               duration={30}
               isPlaying={true}
               initialRemainingTime={30}
               size={window.innerWidth < 400 ? 60 : 100}
               strokeWidth={window.innerWidth < 400 ? 8 : 14}
               strokeLinecap="round"
               rotation="counterclockwise"
               trailColor="#FFFFFF"
               onComplete={handleFinish}
               onUpdate={function () {
                  setSeconds((prev: number) => prev - 1);
               }}
            >
               {({ color }) => (
                  <p className={styles.seconds} style={{ color }}>
                     {seconds}
                  </p>
               )}
            </CountdownCircleTimer>
         </section>
      </section>
   );
};
