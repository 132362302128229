import { Assets } from "pixi.js";

const manifest = {
   bundles: [
      {
         name: "scene",
         assets: [
            {
               alias: "background",
               src: "/background.png",
            },
            {
               alias: "pot",
               src: "/pot.svg",
            },
            {
               alias: "small-pot",
               src: "/small-pot.svg",
            },
            {
               alias: "red-screen",
               src: "/red-screen.png",
            },
         ],
      },
      {
         name: "game-elements",
         assets: [
            {
               alias: "boot",
               src: "/boot.svg",
            },
            {
               alias: "hat",
               src: "/hat.svg",
            },
            {
               alias: "coin",
               src: "/coin.svg",
            },
            {
               alias: "clover",
               src: "/clover.svg",
            },
         ],
      },
   ],
};

let isInitStarted = false;
let isInited = false;

export const initAssets = async () => {
   if (isInitStarted) {
      return isInited;
   }

   isInitStarted = true;

   try {
      await Assets.init({ manifest });

      await Assets.loadBundle("scene");
      await Assets.loadBundle("game-elements");

      isInited = true;
   } catch (error) {
      console.error("Failed to load assets:", error);
      isInited = false;
   }

   return isInited;
};
