"use client";

import { FC } from "react";

import { ReactComponent as BootIcon } from "../../../../assets/boot.svg";
import { ReactComponent as CloverIcon } from "../../../../assets/clover.svg";
import { ReactComponent as CoinIcon } from "../../../../assets/coin.svg";
import { ReactComponent as CoinReward } from "../../../../assets/coin-reward.svg";
import { ReactComponent as CloverReward } from "../../../../assets/clover-reward.svg";
import { ReactComponent as BootFine } from "../../../../assets/boot-fine.svg";
import { ReactComponent as CoinDropLogo } from "../../../../assets/logo.svg";

import { ExplanationItem } from "./ExplanationItem";

import styles from "./ExplanationSection.module.scss";

export const ExplanationSection = () => {
   return (
      <section className={styles.explanation}>
         <ExplanationItem
            title="Collect falling gold coins to earn points"
            reward={CoinReward as unknown as FC}
            item={CoinIcon as unknown as FC}
         />

         <div className={styles.divider}></div>

         <ExplanationItem
            title="Golden Clovers worth bonus points!"
            reward={CloverReward as unknown as FC}
            item={CloverIcon as unknown as FC}
         />

         <div className={styles.divider}></div>

         <ExplanationItem
            title="Avoid the pesky leprechaun trash!"
            reward={BootFine as unknown as FC}
            item={BootIcon as unknown as FC}
         />

         <CoinDropLogo />
      </section>
   );
};
